.interestBoard {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.groupGrid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);  /* Default for desktop */
}

@media (max-width: 1024px) {
  .groupGrid {
    grid-template-columns: repeat(2, 1fr);  /* For tablets */
  }
}

@media (max-width: 768px) {
  .groupGrid {
    grid-template-columns: repeat(1, 1fr);  /* For mobile phones */
  }
}

.groupCard {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.established {
  background-color: #e0f7fa;  /* Light blue for established */
}

.potential {
  background-color: #ffe0b2;  /* Light orange for potential */
}

.input, .button {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  background-color: white;
  color: black;
}

.button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.groupCard:hover {
  transform: translateY(-5px);
}

