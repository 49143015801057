.staff-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
  
.staff-member {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
  
.staff-member img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}
