.slideshow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100vh; /* adjust */
    position: relative;
}

.slide {
    position: absolute;
    width: 90%;
    height: 80%;
    background-size: cover;
    background-position: center;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.slide.active {
    opacity: 1;
}

@media (max-width: 768px) {
    .slideshow-container {
        height: 50vh; /* adjust */
    }
}

@media (max-width: 480px) {
    .slideshow-container {
        height: 30vh; /*adjust */
    }
}
