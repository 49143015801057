.timeline {
	position: relative;
	margin: 50px 0;
	padding: 10px 0;
}

.timeline::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	width: 2px;
	background: #c5c5c5;
	left: 50%;
	margin-left: -1px;
}

.timeline-event {
	position: relative;
	width: 45%;
	padding: 20px 40px;
	box-sizing: border-box;
	margin-bottom: 40px;
}

.timeline-event.left {
	left: 0;
	text-align: right;
}

.timeline-event.right {
	left: 55%;
}

.timeline-event::after {
	content: '';
	position: absolute;
	top: 20px;
	width: 24px;
	height: 24px;
	background: white;
	border: 4px solid #ff9f55;
	border-radius: 50%;
	z-index: 1;
}

.timeline-event.left::after {
	right: -12px;
}

.timeline-event.right::after {
	left: -12px;
}

.timeline-content {
	background: #f9f9f9;
	padding: 20px;
	border-radius: 8px;
	position: relative;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.date {
	font-size: 0.9em;
	color: #888;
	margin-bottom: 10px;
	display: block;
}

@media screen and (max-width: 768px) {
	.timeline::before {
		left: 20px;
		margin: 0;
	}

	.timeline-event {
		width: 100%;
		padding: 20px 20px 20px 60px;
		text-align: left !important;
		left: 0 !important;
	}

	.timeline-event::after {
		display: none;
	}
}
